import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject, onBeforeUnmount } from 'vue'

const Barcode = {
  name: 'Barcode',
  template: `
  <div class="alert alert-danger" role="alert" v-show="!!error">
    {{ error }}
  </div>
  <video ref="videoEl" style="width: 100%;" v-show="!error" />
`,
  emits: [ "detected" ],
  props: {
  },
  setup(props, { emit }) {
    const videoEl = ref(null)
    const error = ref(null)

    let updateTimeInterval = null
    let stream = null

    onBeforeUnmount(() => {
      if (stream) {
        stream.getTracks().forEach(track => { track.stop() })
      }
      if (updateTimeInterval) {
        clearInterval(updateTimeInterval)
      }
    })

    onMounted(async () => {
      if (typeof navigator.mediaDevices?.getUserMedia !== 'function') {
        error.value = "Vaša naprava nima video kamere"
        return
      }
      if ( !('BarcodeDetector' in window )) {
        error.value = "Vaša naprava nima podpore za branje črtne kode"
        // updateTimeInterval = setInterval(async () => {
        //   emit("detected", /*"120F0005"*/ "OVO-67123")
        // }, 10000)
        return
      }

      try {
        stream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: { ideal: 'environment' },
            width: { ideal: 1024 },
            height: { ideal: 768 }
          },
          audio: false
        })
        videoEl.value.playsInline = true
        videoEl.value.srcObject = stream
        await videoEl.value.play()
        const formats = BarcodeDetector.getSupportedFormats() // { formats: ['code_128'] }
        const barcodeDetector = new BarcodeDetector(formats)
        updateTimeInterval = setInterval(async () => {
          const barcodes = await barcodeDetector.detect(videoEl.value)
          barcodes.forEach(barcode => {
            emit("detected", barcode.rawValue)
          })
        }, 500)
      }
      catch (e) {
        if (e?.message) {
          error.value = e.message
        } else {
          error.value = e
        }
      }
    })

    return { videoEl, error }
  }
}

export default Barcode
