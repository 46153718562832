import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from "vue"
import { getDeviceAsync } from "../api"
import { useMsal } from "../composition-api/useMsal"

const DeviceBarcode = {
  name: "DeviceBarcode",
  template: `
  <div v-if="!!entity" class="row mb-2">
    <div class="col-6">
      <div class="card-qrcode">
        <!-- {{ entity.label }} {{ entity.serialNumber }} -->
        <img src="/images/samal-logo.png" alt="Samal d.o.o. logotip">
        <h5>HLADILNA TEHNIKA</h5>
        <ul>
          <li class="tel">📞 070 20 33 33</li>
          <li class="email">✉️ servis@samal.si</li>
        </ul>
      </div>
    </div>
    <div class="col-6" v-html="innerHtmlCode"></div>
    <div class="col-12 end-box">
      <h6>Oznaka</h6>
      <h2>{{ entity?.deviceNumber }}</h2>
    </div>
  </div>
  <div class="row d-print-none">
    <div class="col">
      <button class="btn btn-primary btn-lg me-3" type="button" @click="$window.print()">Natisni</button>
    </div>
  </div>
  `,
  props: {},
  setup(props, { emit }) {
    const route = VueRouter.useRoute()
    const { instance } = useMsal()
    const entity = ref()

    const innerHtmlCode = computed(() => {
      if (!entity.value) {
        return ""
      }
      const qr = qrcode(0, "L")
      qr.addData(entity.value.deviceNumber)
      qr.make()
      return qr.createImgTag()
    })

    const loadDeviceAsync = async () => {
      entity.value = await getDeviceAsync(instance, route.params.deviceId)
    }

    watch(
      () => route.params.deviceId,
      () => loadDeviceAsync()
    )

    onMounted(async () => {
      await loadDeviceAsync()
    })

    return {
      innerHtmlCode,
      entity,
    }
  },
}

export default DeviceBarcode
