import Home from '../views/Home.js'
import Production from '../views/Production.js'

import ServiceOrders from '../views/ServiceOrders.js'
import ProductionOrder from '../views/ProductionOrder.js'
import ProductionOrderBarcode from '../views/ProductionOrderBarcode.js'
import Projects from '../views/Projects.js'
import ServiceOrder from '../views/ServiceOrder.js'
import InternalOrder from '../views/InternalOrder.js'
import ProjectEdit from '../views/ProjectEdit.js'
import LeakageCheckForm from '../views/LeakageCheckForm'
import SignOrder from '../views/SignOrder'
import Workers from '../views/Workers'
import WorkerEdit from '../views/WorkerEdit'
import Customers from '../views/Customers'
import CustomerEdit from '../views/CustomerEdit'
import Warehouses from '../views/Warehouses'
import WarehouseEdit from '../views/WarehouseEdit'
import Materials from '../views/Materials'
import MaterialEdit from '../views/MaterialEdit'
import WarehouseTransfers from '../views/WarehouseTransfers'
import WarehouseTransferEdit from '../views/WarehouseTransferEdit'

import BomTemplates from '../views/BomTemplates'
import BomTemplateEdit from '../views/BomTemplateEdit'

import FacilityServiceOrders from '../views/FacilityServiceOrders'
import Facilities from '../views/Facilities'
import FacilityEdit from '../views/FacilityEdit'
import Devices from '../views/Devices'
import DeviceEdit from '../views/DeviceEdit'
import DeviceBarcode from '../views/DeviceBarcode'
import ServiceOrdersMap from '../views/ServiceOrdersMap'
import Commissions from '../views/Commissions'
import ServiceOrdersWithCompletedMovementsCard from '../components/ServiceOrdersWithCompletedMovementsCard'
import CalibrationReport from '../views/CalibrationReport'


import { registerGuard } from "./Guard"

const routes = [
  {
    path: '/:keepMessages?',
    name: 'Home',
    component: Home,
  },
  {
    path: '/commissions',
    name: 'Commissions',
    component: Commissions,
    meta: { requiresAuth: true }
  },
  {
    path: '/production',
    name: 'Production',
    component: Production,
    meta: { requiresAuth: true }
  },
  {
    path: '/production/order-barcode/:workOrderId',
    name: 'ProductionOrderBarcode',
    component: ProductionOrderBarcode,
    meta: { requiresAuth: true }
  },
  {
    path: '/production/order/:workOrderId/:keepMessages?',
    name: 'ProductionOrder',
    component: ProductionOrder,
    meta: { requiresAuth: true }
  },
  {
    path: '/service/:keepMessages?/:facilityID?/:customerID?/:ownerID?/:statuses?',
    name: 'Service',
    component: ServiceOrders,
    meta: { requiresAuth: true }
  },
  {
    path: '/servicemap/:facilityID?/:customerID?/:ownerID?/:statuses?',
    name: 'ServiceOrdersMap',
    component: ServiceOrdersMap,
    meta: { requiresAuth: true }
  },
  {
    path: '/service/order/:workOrderId/:keepMessages?/:facilityID?/:customerID?/:projectID?',
    name: 'ServiceOrder',
    component: ServiceOrder,
    meta: { requiresAuth: true }
  },
  {
    path: '/service/order-leakage-check/:workOrderId/:leakageCheckFormId/:keepMessages?',
    name: 'ServiceOrderLeakageCheckForm',
    component: LeakageCheckForm,
    meta: { requiresAuth: true }
  },
  {
    path: '/service/order-calibration-report/:workOrderId/:deviceId/:keepMessages?',
    name: 'CalibrationReport',
    component: CalibrationReport,
    meta: { requiresAuth: true }
  },
  {
    path: '/service/order-facility/:workOrderId/:keepMessages?',
    name: 'FacilityServiceOrders',
    component: FacilityServiceOrders,
    meta: { requiresAuth: false }
  },
  {
    path: '/service/order-facility-no/:workOrderId/:keepMessages?',
    name: 'FacilityServiceOrdersNoSign',
    props: { noSign: true },
    component: FacilityServiceOrders,
    meta: { requiresAuth: false }
  },
  {
    path: '/service/order-sign/:workOrderId',
    name: 'SignOrder',
    component: SignOrder,
    meta: { requiresAuth: false }
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
    meta: { requiresAuth: true }
  },
  {
    path: '/projects/:projectId/:productionOrderId?/:keepMessages?',
    name: 'Project',
    component: ProjectEdit,
    meta: { requiresAuth: true }
  },
  {
    path: '/internal/order/:workOrderId/:keepMessages?',
    name: 'InternalOrder',
    component: InternalOrder,
    meta: { requiresAuth: true }
  },
  {
    path: '/workers',
    name: 'Workers',
    component: Workers,
    meta: { requiresAuth: true }
  },
  {
    path: '/workers/:workerId',
    name: 'WorkerEdit',
    component: WorkerEdit,
    meta: { requiresAuth: true }
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers,
    meta: { requiresAuth: true }
  },
  {
    path: '/customers/:customerId',
    name: 'CustomerEdit',
    component: CustomerEdit,
    meta: { requiresAuth: true }
  },
  {
    path: '/warehouses',
    name: 'Warehouses',
    component: Warehouses,
    meta: { requiresAuth: true }
  },
  {
    path: '/warehouses/:id',
    name: 'WarehouseEdit',
    component: WarehouseEdit,
    meta: { requiresAuth: true }
  },
  {
    path: '/bom-templates',
    name: 'BomTemplates',
    component: BomTemplates,
    meta: { requiresAuth: true }
  },
  {
    path: '/bom-templates/:id',
    name: 'BomTemplateEdit',
    component: BomTemplateEdit,
    meta: { requiresAuth: true }
  },
  {
    path: '/materials',
    name: 'Materials',
    component: Materials,
    meta: { requiresAuth: true }
  },
  {
    path: '/materials/:id',
    name: 'MaterialEdit',
    component: MaterialEdit,
    meta: { requiresAuth: true }
  },
  {
    path: '/warehouse-transfer/:type/:id/:keepMessages?',
    name: 'WarehouseTransferEdit',
    component: WarehouseTransferEdit,
    meta: { requiresAuth: true }
  },
  {
    path: '/warehouse-transfer/:keepMessages?',
    name: 'WarehouseTransfers',
    component: WarehouseTransfers,
    meta: { requiresAuth: true }
  },
  {
    path: '/facilities',
    name: 'Facilities',
    component: Facilities,
    meta: { requiresAuth: true }
  },
  {
    path: '/service-orders-with-completed-movements',
    name: 'ServiceOrdersWithCompletedMovementsCard',
    component: ServiceOrdersWithCompletedMovementsCard,
    meta: { requiresAuth: true }
  },
  {
    path: '/facilities/:facilityId',
    name: 'FacilityEdit',
    component: FacilityEdit,
    meta: { requiresAuth: true }
  },
  {
    path: '/devices',
    name: 'Devices',
    component: Devices,
    meta: { requiresAuth: true }
  },
  {
    path: '/devices/:deviceId/:customerId?/:facilityId?',
    name: 'DeviceEdit',
    component: DeviceEdit,
    meta: { requiresAuth: true }
  },
  {
    path: '/devices/:deviceId/barcode',
    name: 'DeviceBarcode',
    component: DeviceBarcode,
    meta: { requiresAuth: true }
  },
  {
    path: '/test',
    name: 'Test',
    component: {
      template: '<button class="btn" @click="test">Test error.</button>',
      setup() {
        const test = () => {
          throw "Error"
        }
        return { test }
      }
    }
  },
  {
    path: '/failed',
    name: 'Failed',
    component: { template: '<h6>Login Failed.</h6>' }
  }
]

const router = VueRouter.createRouter({
  history: window.location.href.includes("localhost") ? VueRouter.createWebHashHistory() : VueRouter.createWebHistory(),
  routes
})

registerGuard(router)

export default router
