import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from "vue"
const MaterialsList = {
  template: `
<div class="row" v-if="materials?.length">
  <div class="col d-block d-md-none">
    <div class="card mb-2 mt-2" v-for="m in materials.filter(m => !m.isForDelete)">
      <div class="card-body" role="button" @click="$emit('selectMaterial', m)">
        <h6 class="card-subtitle"><span class="fw-light">{{ m.quantity }}{{ m.measurementUnit }}</span> {{ m.materialName }}</h6>
      </div>
    </div>
  </div>
  <div class="col d-none d-md-block d-lg-block">
    <div class="table-responsive">
      <table class="table table-sm table-hover" v-show="materials.filter(m => m.isForDelete !== true).length">
        <thead class="thead-light">
          <tr>
            <th v-if="showCommission">Komisioniranje</th>
            <th v-if="showSelect"></th>
            <th v-if="showSection"
              class="sortable"
              :class="{ asc: sort.by === 'section' && sort.dir === 'asc', desc: sort.by === 'section' && sort.dir === 'desc'  }"
              @click="$emit('sort', { by: 'section', dir: sort.dir === 'desc' ? 'asc' : 'desc'  })">Sekcija</th>
            <th class="sortable"
              :class="{ asc: sort.by === 'materialName' && sort.dir === 'asc', desc: sort.by === 'materialName' && sort.dir === 'desc'  }"
              @click="$emit('sort', { by: 'materialName', dir: sort.dir === 'desc' ? 'asc' : 'desc'  })">Naziv</th>
            <th v-if="false">Kataloška št.</th>
            <th>Ident</th>
            <th>Količina</th>
            <th v-if="showStock">Prosto CE</th>
            <th v-if="showCommission">Komision</th>
            <th v-if="showMissingCommission">Manjka</th>
            <th v-show="showWarehouse">Skladišče</th>
            <th v-show="showNotPartOfOffer">Ni del ponudbe</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="m in materials.filter(m => !m.isForDelete)" :class="{
            clickable: canChange,
            'bg-warning-light': m.sectionName === 'Ni na dokumentu.',
            'bg-success-light': m.isReplacing,
            'strong': m.hasReplacment,
            'bg-success': m.quantity - m.commissionCompletedQuantity <= 0 && m.sectionName !== 'Ni na dokumentu.' && !m.isReplacing,
            'bg-info': (m.quantity - m.commissionCompletedQuantity > 0) && (m.commissionedQuantity - m.commissionCompletedQuantity > 0)
          }">
            <td v-if="showCommission"><input type="number" v-model="m.commissionQuantity" @change="$emit('changeCommission', m)" class="form-control form-control-sm" style="width: 100px"></td>
            <td v-if="showSelect"><input type="checkbox" v-model="m.selected" @change="$emit('changeSelection', m)"></td>
            <td v-if="showSection" @click="$emit('selectSection', { name: m.sectionName, order: m.sectionOrder })">{{ m.sectionName }}</td>
            <td @click="$emit('selectMaterial', m)">{{ m.materialName }}
              <br v-show="m.requiresSerialNumbers" />
              <span class="badge bg-secondary" v-show="m.requiresSerialNumbers && m.serialNumbers">Serijske</span>
              <span class="badge bg-danger" v-show="m.requiresSerialNumbers && !m.serialNumbers">Serijske</span>

              <span class="ms-1 badge bg-info text-dark" v-for="sn in m.serialNumbers?.split(REGX_NR)">{{ sn }}</span>

              <span v-show="m.hasReplacment && (m.commissionedQuantity - m.totalReplacementQuantity) > 0" class="text-light">({{ m.commissionedQuantity - m.totalReplacementQuantity }}{{ m.measurementUnit }})</span>

              <p v-show="m.note" v-html="m.note" class="replacement-note"></p>
              <p v-show="m.movementNotes" v-html="m.movementNotes" class="movement-notes"></p>

            </td>
            <td @click="$emit('selectMaterial', m)">{{ m.opPisProductID }}</td>

            <td @click="$emit('selectMaterial', m)">{{ m.quantity }}{{ m.measurementUnit }}</td>

            <td @click="$emit('selectMaterial', m)" v-if="showStock && m.orderedQuantity <= 0"><span v-show="m.isStockUpToDate">{{ m.stockCe - m.reservedCe }} {{ m.measurementUnit }}</span><span v-show="!m.isStockUpToDate">Zaloga ni ažurna</span></td>
            <td @click="$emit('selectMaterial', m)" v-if="showStock && m.orderedQuantity > 0"><span v-show="m.isStockUpToDate">{{ m.stockCe - m.reservedCe }}</span><span v-show="!m.isStockUpToDate">Zaloga ni ažurna</span>&nbsp;+&nbsp;<span class="badge bg-primary">{{ m.orderedQuantity }} @
              {{ $dayjs(m.expectedDelivery).year() > 99 ? $dayjs(m.expectedDelivery).format($DATE_FORMAT_DISPLAY) : 'Ni roka dobave.' }}
            </span></td>

            <td @click="$emit('selectMaterial', m)" v-if="showCommission"  class="nowrap" :class="{ 'strikethrough': m.hasReplacment }">
              <span v-show="m.commissionedQuantity && (m.commissionedQuantity !== m.commissionCompletedQuantity)">{{ m.commissionedQuantity }}</span>
              <span v-show="m.commissionCompletedQuantity">[🚚{{ m.commissionCompletedQuantity }}]</span>
              <span v-show="m.commissionCompletedQuantity || m.commissionedQuantity">{{ m.measurementUnit }}</span>
            </td>
            <td @click="$emit('selectMaterial', m)" v-if="showMissingCommission">
              <span v-if="m.quantity > 0 && m.commissionedQuantity > 0 && m.quantity !== m.commissionCompletedQuantity"
                :class="{ 'bg-danger': m.quantity - m.commissionCompletedQuantity > 0, 'bg-warning': m.quantity - m.commissionCompletedQuantity < 0 }"
                class="badge ">
                {{ m.quantity - m.commissionCompletedQuantity }}{{ m.measurementUnit }}
              </span>
            </td>
            <td @click="$emit('selectMaterial', m)" v-if="showWarehouse"><DisplayEntity apiEntity="Warehouses" v-model="m.warehouseID" /></td>
            <td @click="$emit('selectMaterial', m)" v-if="showNotPartOfOffer">{{ m.notPartOfOffer ? 'Dodatno!' : '' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
  `,
  props: {
    modelValue: { type: Array },
    canChange: { type: Boolean },
    showCommission: { type: Boolean, default: false },
    showMissingCommission: { type: Boolean, default: false },
    showSection: { type: Boolean, default: true },
    showWarehouse: { type: Boolean, default: true },
    showSelect: { type: Boolean, default: false },
    showStock: { type: Boolean, default: false },
    sort: { type: Object, default: () => ({ by: "", dir: "" }) },
    showNotPartOfOffer: { type: Boolean, default: false },
  },
  emits: ["selectMaterial", "selectSection", "changeSelection", "changeCommission", "sort"],
  setup(props, { emit }) {
    const materials = toRef(props, "modelValue")
    const materialForActions = ref(null)
    const REGX_NR = /[\r\n]+/

    return {
      REGX_NR,
      materialForActions,
      materials,
    }
  },
}

export default MaterialsList
