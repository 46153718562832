import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { EMPTY_GUID } from '../utils/constants'
import Barcode from './Barcode'
import { searchDevicesAsync } from '../api'

const SelectDevice = {
  name: 'SelectDevice',
  components: { Barcode },
  template: `
  <Modal :visible="visible" @close="{ reset(); $emit('close') }" title="Izberi napravo">
    <div class="alert alert-warning" role="alert" v-show="!!info">
      {{ info }}
    </div>
    <div class="row" v-if="!device">
      <div class="col">
        <Barcode @detected="searchDeviceAsync" v-if="visible" />
      </div>
    </div>
    <div class="row" v-else>
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h6 class="card-subtitle mb-2 text-muted">{{ device.deviceNumber }}</h6>
            <p class="card-text">{{ device.label }}</p>
          </div>
        </div>
      </div>
    </div>

    <template v-slot:buttons>
      <button class="btn btn-outline-secondary" type="button" @click="device = null" :disabled="!device">Izber drugo napravo</button>
      <button class="btn btn-success me-3" type="button" @click="addToWorkorder" :disabled="!device">Poveži napravo z nalogom</button>
    </template>
  </Modal>
   `,
   emits: ["add", "close"],
  props: {
    facilityId: { type: String, required: true },
    visible: { type: Boolean }
  },
  setup (props, { emit }) {
    const { instance } = useMsal()

    const device = ref(null)
    const info = ref(null)

    const reset = () => {
      info.value = ""
      device.value = null
    }

    const searchDeviceAsync = async bc => {
      if (!bc) return
      try {
        const temp = await searchDevicesAsync(instance, bc)
        if (temp.length > 0 && temp[0].facilityID === props.facilityId) {
          device.value = temp[0]
        }
        else if (temp.length > 0) {
          info.value = "Naprava ni povezana s trenutnim objektom: " + bc
        } else {
          info.value = "Ni materiala s to kodo: " + bc
        }
      } catch (e) {
        info.value = e
      }
    }

    const addToWorkorder = () => {
      if (device.value.id && device.value.id != EMPTY_GUID) {
        emit('add', { ... device.value })
        emit('close')
      }
      reset()
    }

    return { info, device, reset, addToWorkorder, searchDeviceAsync }
  }
}

export default SelectDevice
