import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useWorkOrder } from 'scripts/composition-api/useWorkOrder'

const ProductionOrderBarcode = {
  name: 'ProductionOrderBarcode',
  template: `
  <div v-if="workOrder?.status !== 12" class="row mb-5">
    <div class="col-lg-6" v-for="sn in workOrder.serialNumbers">
      <div class="card card-barcode">
        <div class="card-body">
          <h5 class="card-title">{{ workOrder.material.name }}</h5>
          <div class="barcodes" v-if="workOrder.material?.barCode?.length <= 8 && String(sn.number)?.length <= 5">
            <p class="card-text barcode">
              <span class="barcode barcode-first">{{ $filters.encode128(workOrder.material?.barCode) }}</span>
              <span class="barcode-nmr">{{ workOrder.material?.barCode }}</span>
            </p>
            <p class="card-text barcode">
              <span class="barcode">{{ $filters.encode128(String(sn.number)) }}</span>
              <span class="barcode-nmr">{{ sn.number }}</span>
            </p>
          </div>
          <div class="barcodes" v-else>
            <p>Predolga črtna koda (max 8 in 5 znakov): {{ workOrder.material?.barCode }} / {{ String(sn.number) }}</p>
          </div>
        </div>
      </div>
      <div class='page-break'></div>
    </div>
  </div>

  <div v-else class="row d-print-none">
    Proizvodni nalog ali serijske številke ne obstajajo.
  </div>
  <div class="row d-print-none">
    <div class="col">
      <button class="btn btn-primary btn-lg me-3" type="button" @click="$window.print()">Natisni</button>
    </div>
  </div>
  `,
  props: {
  },
  setup (props, { emit }) {
    const { workOrder } = useWorkOrder("Production")

    return {
      workOrder
    }
  }
}

export default ProductionOrderBarcode
