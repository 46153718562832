import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { EMPTY_GUID } from '../utils/constants'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { useSerialNumbers } from "../composition-api/useSerialNumbers"
import Barcode from './Barcode'
import { searchMaterialsAsync } from '../api'

const SelectMaterial = {
  name: 'SelectMaterial',
  components: { Barcode },
  template: `
  <Modal :visible="visible" @close="{ reset(); $emit('close') }" title="Izberi material">
    <div class="alert alert-warning" role="alert" v-show="!!info">
      {{ info }}
    </div>

    <div class="alert alert-warning" role="alert" v-show="material?.requiresSerialNumbers && !ignoreSerial">
      Izbrani material zahteva serijske številke ({{ numberOfSerialNumbers }} / {{ material?.quantity }})
    </div>

    <div class="row mb-3" v-show="useBarcode && quantity > 0">
      <div class="col">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="auto-add" v-model="autoAdd" />
          <label class="form-check-label" for="auto-add">Avtomatsko dodajanje</label>
        </div>
      </div>
    </div>

    <div class="row" v-if="material">
      <div class="col-lg-6">
        <div class="card text-white bg-secondary">
          <div class="card-body">
            <h5 class="card-title">{{ material.materialName }}</h5>
            <h6 class="card-subtitle mb-2 text-muted">{{ material.catalogNumber }} {{ material.opPisProductID }}</h6>
            <p class="card-text">{{ material.quantity }} {{ material.measurementUnit }}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6" v-if="!noSection">
        <div class="card text-dark bg-light">
          <div class="card-body">
            <h5 class="card-title">{{ section.name }}</h5>
            <h6 class="card-subtitle mb-2 text-muted">zaporedna številka: {{ section.order }}</h6>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2" v-if="material">
      <label class="col-form-label col-sm-3">Količina *</label>
      <div class="col-sm-3">
        <input class="form-control" type="number" v-model="material.quantity"  />
      </div>
    </div>

    <div class="row mt-2" v-if="material?.requiresSerialNumbers && !ignoreSerial">
      <label class="col-form-label col-sm-3">Serijske številke (vsaka v svojo vrstico) *</label>
      <div class="col-sm-3">
        <textarea class="form-control" :rows="parseInt(Math.min(material.quantity, 10))" v-model="material.serialNumbers"></textarea>
      </div>
    </div>

    <div class="row" v-if="!material">
      <div class="col" v-if="useBarcode">
        <Barcode @detected="searchBarcodeAsync" v-if="visible" />
      </div>
      <div class="col" v-else>
        <div class="mb-3">
          <label class="form-label">Poišči material</label>
          <Autocomplete :max="20" ref="autoCompleteEl" class="form-control" @input="getMaterialsAsync" @onSelect="selectMaterial" :results="materials"></Autocomplete>
        </div>
      </div>
    </div>

    <template v-slot:buttons>
      <button class="btn btn-outline-secondary" type="button" @click="material = null" v-show="!autoAdd" :disabled="!material">Izber drug material</button>
      <button class="btn btn-success me-3"
        type="button"
        @click="addToWorkorder"
        v-show="!autoAdd"
        :disabled="!material || (!section && !noSection)  || (material.requiresSerialNumbers && !ignoreSerial && !serialNumbersValid)">Dodaj material na nalog</button>
    </template>
  </Modal>`,
  emits: ["add", "close"],
  props: {
    quantity: { type: Number, default: 1 },
    section: { type: Object },
    noSection: { type: Boolean, default: false },
    noWarehouse: { type: Boolean, default: false },
    visible: { type: Boolean },
    useBarcode: { type: Boolean, default: false },
    ignoreSerial: { type: Boolean, default: false }
  },
  setup(props, { emit }) {
    const { instance } = useMsal()
    const materials = ref([])
    const material = ref(null)
    const { worker } = useIsAuthorized()
    const autoCompleteEl = ref(null)
    const info = ref(null)
    const autoAdd = ref(false)

    const { numberOfSerialNumbers, serialNumbersValid } = useSerialNumbers(material)

    const selectMaterial = m => {
      material.value = {
        materialID: m.id,
        materialName: m.name,
        catalogNumber: m.catalogNumber,
        opPisProductID: m.opPisProductID,
        quantity: props.quantity * (m.quantity || 0),
        measurementUnit: m.measurementUnit,
        requiresSerialNumbers: m.requiresSerialNumbers,
        serialNumbers: ''
      }
      if (!props.noSection) {
        material.value.sectionOrder = props.section.order
        material.value.sectionName = props.section.name
      }
      if (!props.noWarehouse) {
        material.value.warehouseID = worker.activeWarehouse.value.id
      }
    }

    const searchBarcodeAsync = async bc => {
      const temp = await searchMaterialsAsync(instance, "acCode", bc)
      if (temp.length > 0) {
        selectMaterial(temp[0])
        if ((!temp[0].requiresSerialNumbers || props.ignoreSerial) && autoAdd.value) {
          setTimeout(() => addToWorkorder(), 1000)
        }
      } else {
        info.value = "Ni materiala s to kodo: " + bc
      }
    }

    const getMaterialsAsync = async q => {
      materials.value = await searchMaterialsAsync(instance, "q", q)
      materials.value = materials.value.map(m => {
        if (m.isStockUpToDate) {
          m.name +=  " [" + m.freeStockCe + " " + m.measurementUnit + "@CE]"
        } else {
          m.name +=  " [zaloga ni ažurna]"
        }
        return m
      })
    }

    const reset = () => {
      info.value = ""
      material.value = null
      materials.value = []
      autoCompleteEl.value?.setText("")
    }

    const addToWorkorder = () => {
      if (material.value.materialID && material.value.materialID != EMPTY_GUID) {
        emit('add', [{ ...material.value }])
      }
      reset()
    }

    return { info, reset, autoAdd, material, materials, getMaterialsAsync, selectMaterial, autoCompleteEl, addToWorkorder, searchBarcodeAsync, serialNumbersValid, numberOfSerialNumbers }
  },
}

export default SelectMaterial
