import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { setError, startLoading, stopLoading, setSuccess } from "../components/Notification"
import { getEntityAsync, saveSimpleEntityAsync } from "../api"
import SelectMaterial from '../components/SelectMaterial'

export default BomTemplateEdit = {
  name: "BomTemplateEdit",
  components: { SelectMaterial},
  template: `
  <div v-if="entity">
    <div class="row mt-3" >
      <label class="col-form-label col-sm-4">Naziv</label>
      <div class="col-sm-8">
        <input class="form-control" v-model="entity.name" required />
      </div>
    </div>
    <div class="row mt-3" >
      <label class="col-form-label col-sm-4">Komentar</label>
      <div class="col-sm-8">
        <input class="form-control" v-model="entity.comment" />
      </div>
    </div>

    <div class="row mt-5 mb-3" v-show="!isNew">
      <div class="col">
        <button class="btn btn-outline-warning" type="button" @click="entity.sections.push({ name: '', order: entity.sections.length + 1, materials: [] })">Dodaj sekcijo</button>
      </div>
    </div>

    <div class="card mb-2 bg-light" v-for="section in entity.sections">
      <div class="card-body">
        <div class="row mt-3" >
          <label class="col-form-label col-sm-4">Naziv sekcije</label>
          <div class="col-sm-8">
            <input class="form-control" v-model="section.name" required />
          </div>
        </div>
        <div class="row mt-3" >
          <label class="col-form-label col-sm-4">Vrstni red</label>
          <div class="col-sm-8">
            <input class="form-control" v-model="section.order" type="number" />
          </div>
        </div>

        <hr>

        <div class="row mt-3" >
          <div class="col-md-4">
            <button type="button"
              class="btn btn-outline-warning"
              @click="sectionForAddMaterial = section">Dodaj <span class="d-none d-md-inline">material</span></button>
          </div>
          <div class="table-responsive col-md-8">
            <table class="table table-stripped table-sm table-hover" v-if="section.materials?.length">
              <thead>
                <tr>
                  <th scope="col">Material</th>
                  <th scope="col">Kataloška št.</th>
                  <th scope="col">Količina</th>
                  <th scope="col">Enota mere</th>
                  <th scope="col">Opomba</th>
                  <th scope="col">Akcije</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="m in section.materials">
                  <td>{{ m.name }}</td>
                  <td>{{ m.catalogNumber }}</td>
                  <td>{{ m.quantity }}</td>
                  <td>{{ m.measurementUnit }}</td>
                  <td>{{ m.comment }}</td>
                  <td><button class="btn btn-sm btn-outline-danger" @click="section.materials.splice(section.materials.indexOf(m), 1)">Briši</button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>

    <SelectMaterial
      :ignoreSerial="true"
      :section="sectionForAddMaterial"
      :visible="!!sectionForAddMaterial"
      @add="addMaterial" />

    <div class="row mt-5">
      <div class="col-2 text-start">
      </div>
      <div class="col-10 text-end">
        <button class="btn btn-outline-secondary me-3" type="button" @click="$router.push({ name: 'BomTemplates' })">Prekliči</button>
        <button class="btn btn-success btn-lg me-3" type="button" @click="saveEntityAsync">Shrani</button>
      </div>
    </div>

  </div>

  `,
  setup (props, { emit }) {
    const route = VueRouter.useRoute()
    const router = VueRouter.useRouter()
    const { instance } = useMsal()
    const entity = ref(null)
    const sectionForAddMaterial = ref(null)

    const isNew = computed(() => route.params.id === "new")

    const loadEntityAsync = async () => {
      if (!route.params.id) {
        entity.value = null
        return
      }
      entity.value = await getEntityAsync(instance, "BomTemplate", route.params.id)
      if (!entity.value) {
        setError("Ne obstaja.")
      }
    }

    const addMaterial = (m) => {
      const section = entity.value.sections.find(s => s === sectionForAddMaterial.value)
      if (!section || !m || !m.length) {
        setError("Ne obstaja.")
        return
      }
      const materialToAdd = {
        id: m[0].materialID,
        name: m[0].materialName,
        catalogNumber: m[0].catalogNumber,
        quantity: m[0].quantity || 0,
        measurementUnit: m[0].measurementUnit,
        comment: ""
       }
      section.materials.push(materialToAdd)
      sectionForAddMaterial.value = null
    }

    const saveEntityAsync = async () => {
      const result = await saveSimpleEntityAsync(instance, "BomTemplate", entity.value, isNew.value)
      if (!result) return
      if (isNew.value) {
        router.push({ name: 'BomTemplateEdit', params: { id: entity.value.id, keepMessages: true } })
      } else {
        await loadEntityAsync()
      }
    }

    watch(() => route.params.id, () => loadEntityAsync())

    onMounted(async () => await loadEntityAsync())

    return { entity, isNew, saveEntityAsync, sectionForAddMaterial, addMaterial }
  }
}
