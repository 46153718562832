import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from "vue"
import { useMsal } from "../composition-api/useMsal"
import { setError, startLoading, stopLoading, setSuccess, setWarning } from "./Notification"
import { WorkOrderMaterialActions } from "./WorkOrderMaterialActions"
import BomTemplates from "../components/BomTemplates"
import SelectMaterial from "../components/SelectMaterial"
import MaterialsList from "../components/MaterialsList"
import { getMaterialsAsync, saveMaterialsAsync } from "../api"

const WorkOrderMaterials = {
  components: { WorkOrderMaterialActions, BomTemplates, SelectMaterial, MaterialsList },
  template: `
<div class="row">
  <h4 class="col-2">Material</h4>

  <div class="col text-end">
    <div class="form-check form-check-inline bg-info pl-3" v-if="$window.CONTEXT === 'stage'" v-show="showCommission || showCommissionForAll">
      <div class="m-3">
        <input class="form-check-input" type="checkbox" id="bypassCheckbox" v-model="bypassCommissioning" />
        <label class="form-check-label" for="bypassCheckbox">Preskoči dlančnik za komisioniranje</label>
      </div>
    </div>
    <button type="button" class="btn btn-success btn-sm ms-1 me-2 mb-2" v-show="showCommissionForAll" @click="asyncCreateCommissionForAll" :disabled="materials.length < 1 || disableCommission">Zahtevaj komisioniranje celotnega materiala</button>
    <button type="button" class="btn btn-success btn-sm ms-1 me-2 mb-2" v-show="showCommission" @click="asyncAddMaterialsCommission" :disabled="disableCommission">Zahtevaj komisioniranje</button>
    <button type="button" class="btn btn-info btn-sm me-2 mb-2" v-show="canChange && enableTemplates" @click="showBomTemplates = true"><span class="d-none d-md-inline">Dodaj material</span> iz predloge</button>
    <button type="button" class="btn btn-warning btn-sm me-2 mb-2" v-show="canChange" @click="sectionForAddMaterial = { order: 1000, name: 'Ročno dodan' }">Dodaj <span class="d-none d-md-inline">material</span></button>
    <button type="button" class="btn btn-secondary btn-sm me-2 mb-2" v-show="canChange && enableBarcode" @click="sectionForAddMaterialBarcode = { order: 1100, name: 'Čitalec' }">Bralnik <span class="d-none d-md-inline">črtne kode (material)</span></button>
  </div>
</div>

<MaterialsList
  v-model="materials"
  :canChange="canChange"
  :showCommission="showCommission"
  :showMissingCommission="showMissingCommission"
  :showWarehouse="showWarehouse"
  :showSection="showSection"
  :showSelect="showSelect"
  :showStock="showStock"
  :sort="sort"
  :showNotPartOfOffer="showNotPartOfOffer"
  @selectMaterial="m => { if (canChange) { materialForActions = m; } }"
  @selectSection="s => { sectionForAddMaterial = s; }"
  @changeSelection="e => $emit('changeSelection', e)"
  @sort="e => { sort.by = e.by; sort.dir = e.dir; }"
  />

<BomTemplates
  :visible="showBomTemplates"
  :quantity="quantity"
  @close="showBomTemplates = false"
  @add="asyncAddMaterials" />

<WorkOrderMaterialActions
  v-model="materialForActions"
  @close="e => { if (e === true) { loadMaterialsAsync(workOrderType, workOrderId); }; materialForActions = null }"
  :canChange="canChange"
  :showWarehouse="showWarehouse"
  :workOrderType="workOrderType"
  :workOrderId="workOrderId"
  :showOrderMaterial="showOrderMaterial"
  :showNotPartOfOffer="showNotPartOfOffer"
  :canDeleteMaterial="canDeleteMaterial"
  />

<SelectMaterial
  v-if="canChange"
  :quantity="quantity"
  :useBarcode="true"
  :ignoreSerial="ignoreSerial"
  :section="sectionForAddMaterialBarcode"
  :visible="!!sectionForAddMaterialBarcode"
  @close="sectionForAddMaterialBarcode = null"
  @add="asyncAddMaterials" />

<SelectMaterial
  v-if="canChange"
  :quantity="quantity"
  :ignoreSerial="ignoreSerial"
  :section="sectionForAddMaterial"
  :visible="!!sectionForAddMaterial"
  @close="sectionForAddMaterial = null"
  @add="asyncAddMaterials" />

`,
  emits: ["changeSelection", "commissioned"],
  props: {
    canChange: Boolean,
    workOrderType: { type: String, required: true },
    workOrderId: { type: String },
    quantity: { type: Number, default: 1 },
    enableTemplates: { type: Boolean, default: false },
    enableBarcode: { type: Boolean, default: true },
    showWarehouse: { type: Boolean, default: false },
    showSection: { type: Boolean, default: true },
    showSelect: { type: Boolean, default: false },
    showCommission: { type: Boolean, default: false },
    showCommissionForAll: { type: Boolean, default: false },
    showMissingCommission: { type: Boolean, default: false },
    disableCommission: { type: Boolean, default: false },
    showStock: { type: Boolean, default: false },
    showOrderMaterial: { type: Boolean, default: false },
    ignoreSerial: { type: Boolean, default: false },
    showNotPartOfOffer: { type: Boolean, default: false },
    canDeleteMaterial: { type: Boolean, default: true },
  },
  setup(props, { emit }) {
    const { instance } = useMsal()
    const materialForActions = ref(null)
    const sectionForAddMaterial = ref(null)
    const sectionForAddMaterialBarcode = ref(null)
    const showBomTemplates = ref(false)
    const materials = ref([])
    const sort = ref({ by: "", dir: "" })
    const bypassCommissioning = ref(window.CONTEXT === "stage")

    const loadMaterialsAsync = async (materialRelation, relationId) => {
      materials.value = await getMaterialsAsync(instance, materialRelation, relationId, sort.value)
    }

    const asyncAddMaterials = async (m) => {
      const materialsToAdd = []
      m.forEach((m) => {
        const found = materials.value.find((e) => e.materialID === m.materialID)
        if (!found) {
          m.isNew = true
          materials.value.push(m)
          materialsToAdd.push(m)
        } else if (!found.isForDelete) {
          found.quantity += m.quantity
          found.isDirty = true
          materialsToAdd.push(found)
        } else {
          found.quantity = m.quantity
          found.isDirty = true
          found.isForDelete = false
          materialsToAdd.push(found)
          console.warn("adding deleted material")
        }
      })
      const result = await saveMaterialsAsync(instance, materialsToAdd, props.workOrderType, props.workOrderId)
      if (result) {
        await loadMaterialsAsync(props.workOrderType, props.workOrderId)
        setSuccess("Material dodan..", true)
        showBomTemplates.value = false
      } else {
        console.error("materials not saved.")
      }
    }

    const asyncAddMaterialsCommission = async () => {
      const commissionMaterials = JSON.parse(JSON.stringify(materials.value.filter((m) => m.commissionQuantity > 0)))
      if (commissionMaterials.length === 0) {
        return
      }
      commissionMaterials.forEach((m) => {
        m.quantity = m.commissionQuantity
        m.isNew = true
        m.bypassCommissioning = bypassCommissioning.value
      })
      const result = await saveMaterialsAsync(
        instance,
        commissionMaterials,
        `Commission${props.workOrderType}`,
        props.workOrderId
      )
      if (result) {
        emit("commissioned")
        setSuccess("Zahtevek za komisioniranje oddan.", true)
        await loadMaterialsAsync(props.workOrderType, props.workOrderId)
      } else {
        setError("Komisioniranje ni bilo uspešno.")
      }
    }

    const asyncCreateCommissionForAll = async () => {
      materials.value.forEach(async (m) => (m.commissionQuantity = m.quantity))
      await asyncAddMaterialsCommission()
    }

    watch(
      () => props.workOrderId,
      async (newId) => {
        if (newId) {
          await loadMaterialsAsync(props.workOrderType, props.workOrderId)
        }
      },
      { immediate: true }
    )

    watch(
      () => sort.value,
      async (newSort) => {
        await loadMaterialsAsync(props.workOrderType, props.workOrderId)
      },
      { deep: true }
    )

    const reload = async () => {
      console.log("reload materials")
      await loadMaterialsAsync(props.workOrderType, props.workOrderId)
    }

    return {
      materials,
      loadMaterialsAsync,
      asyncAddMaterials,
      asyncAddMaterialsCommission,
      asyncCreateCommissionForAll,
      showBomTemplates,
      materialForActions,
      sectionForAddMaterial,
      sectionForAddMaterialBarcode,
      sort,
      reload,
      bypassCommissioning,
    }
  },
}

/*
const useWorkOrderMaterials = () => {

  const changeWorkOrderMaterials = m => {
    const index = workOrderMaterials.value.findIndex(e => e.materialID === m.materialID)
    if (index < 0) {
      throw new Error(`count't find ${m.materialID}`)
    }
    if (!m.isNew) {
      m.isDirty = true
    }
    workOrderMaterials.value[index] = m
  }

  const removeMaterial = m => {
    const index = workOrderMaterials.value.indexOf(m)
    if (index >= 0) {
      workOrderMaterials.value[index].isForDelete = true
    } else {
      console.warn(`${m?.materialName} not found.`)
    }
  }

  return {
    materials,
    loadMaterialsAsync,
    changeWorkOrderMaterials,
    addMaterials,
    removeMaterial,
  }
} */

export { WorkOrderMaterials /*, useWorkOrderMaterials */ }
