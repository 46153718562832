import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from "vue"
import { getDeviceAsync, saveDeviceAsync } from "../api"
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { DATETIME_FOR_LOCAL_INPUT, EMPTY_GUID } from "../utils/constants"
import { useEnums } from "../composition-api/useEnums"
import { useMsal } from "../composition-api/useMsal"
import ImageInput from "../components/ImageInput"
import FacilityHistory from "../components/FacilityHistory"

const DateOnly = {
  name: "DateOnly",
  template: `<div>
  <label class="col-form-label col-sm-4" v-if="label">{{ label }}</label>
  <div :class="{ 'col-sm-8': !!label }">
    <div class="input-group">
      <input class="form-control" type="date" :value="modelValue" :disabled="disabled" @change="$emit('update:modelValue', $event.target.value)" />
      <div class="input-group-append ms-3" v-show="withClear">
        <button class="btn btn-outline-warning" type="button" @click="$emit('update:modelValue', '')">Izbriši datum</button>
      </div>
    </div>
  </div>
</div>`,
  props: {
    label: { type: String, required: false },
    withClear: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    modelValue: { type: String },
    emits: ["update:modelValue"],
  },
}

const DeviceEdit = {
  name: "DeviceEdit",
  components: { ImageInput, FacilityHistory, DateOnly },
  template: `
  <div v-if="!!entity">
    <div class="row mb-3 mt-3">
      <div class="col-sm-4"><h1>{{ entity.deviceNumber }}</h1></div>
      <div class="col-sm-4 d-none d-lg-block"><h3>Naprava</h3></div>
      <div class="col-sm-4 text-end">
        <router-link :to="{  name: 'CustomerEdit', params: { customerId: entity.customerID } }" v-if="entity.customerID" class="link-warning ms-3">Stranka</router-link>
        <router-link :to="{  name: 'FacilityEdit', params: { facilityId: entity.facilityID } }" v-if="entity.facilityID" class="link-success ms-3">Objekt</router-link>
        <router-link :to="{  name: 'DeviceBarcode', params: { deviceId: entity.id } }" v-show="!isNew && entity.deviceNumber" target="_blank" class="link-dark ms-3">Črtna koda</router-link>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-9">
        <div class="row">
          <div class="col">
            <SelectCustomerEntity
              required
              :customerID="entity.customerID"
              :facilityID="entity.facilityID"
              :canChange="isNew"
              @change="e => { entity.customerID = e.customerID;  entity.facilityID = e.facilityID }"
            />
          </div>
        </div>
        <SelectEnum
          class="row mb-3"
          required
          :showAll="false"
          :disabled="!isNew"
          v-model="entity.typeText"
          :types="deviceTypes"
          label="Vrsta naprave" />
        <div class="row mb-3">
          <label class="form-check-label col-sm-4" for="is-active">Aktivna</label>
          <div class="col-sm-8">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="is-active" v-model="entity.isActive"  />
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <label class="col-form-label col-sm-4">Oznaka - nalepka</label>
          <div class="col-sm-8">
            <input class="form-control" type="text" v-model="entity.label" :disabled="!(isNew || worker.isAdmin)" required />
          </div>
        </div>
        <div class="row mb-3" v-show="entity.typeShort !== 'THM'">
          <label class="col-form-label col-sm-4">Dobavitelj</label>
          <div class="col-sm-8">
            <input class="form-control" type="text" v-model="entity.supplier" :disabled="!(isNew || worker.isAdmin)" />
          </div>
        </div>
        <div class="row mb-3" v-show="entity.typeShort !== 'THM'">
          <label class="col-form-label col-sm-4">Leto izdelave</label>
          <div class="col-sm-4 col-md-2">
            <input class="form-control" type="number" v-model="entity.yearOfManufacture" pattern="[0-9]{4}" :disabled="!(isNew || worker.isAdmin)" required />
          </div>
        </div>
        <div class="row mb-3" v-show="entity.typeShort !== 'THM'">
          <label class="col-form-label col-sm-4">Serijska številka</label>
          <div class="col-sm-8">
            <input class="form-control" type="text" v-model="entity.serialNumber" :disabled="!(isNew || worker.isAdmin)" />
          </div>
        </div>
        <div class="row mb-3" v-if="entity.isSlave">
          <label class="col-form-label col-sm-4">Vezano na agregat</label>
          <div class="col-sm-8">
            <SelectEntity
              required
              :apiEntity="'Devices?facilityId=' + entity.facilityID + '&type=CoolingUnit'"
              v-model="entity.sourceDeviceID" />
          </div>
        </div>

        <DateOnly class="row mb-3" v-model="entity.warrantyValidUntil" label="Garancija do" :disabled="!(isNew || worker.isAdmin)"  v-show="entity.typeShort !== 'THM'" />

        <div class="row mb-3" v-show="entity.hasLeakageTest ">
          <label class="col-form-label col-sm-4">Preverjanja uhajanja</label>
          <div class="col-sm-3">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="leakageTestIntervalMonths" id="leakageTestIntervalMonths1" value="3" v-model="entity.leakageTestIntervalMonths" :disabled="!worker.isAdmin">
              <label class="form-check-label" for="leakageTestIntervalMonths1">3</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="leakageTestIntervalMonths" id="leakageTestIntervalMonths2" value="6" v-model="entity.leakageTestIntervalMonths" :disabled="!worker.isAdmin">
              <label class="form-check-label" for="leakageTestIntervalMonths2">6</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="leakageTestIntervalMonths" id="leakageTestIntervalMonths3" value="12" v-model="entity.leakageTestIntervalMonths" :disabled="!worker.isAdmin">
              <label class="form-check-label" for="leakageTestIntervalMonths3">12</label>
            </div>
          </div>
          <DateOnly class="col-sm-5" v-if="entity.hasLeakageTest" v-model="entity.lastLeakageTest" :disabled="!worker.isAdmin" />
        </div>


        <div class="row mb-3" v-show="entity.hasRegularService && !isNew">
          <label class="col-form-label col-sm-4">Servis</label>
          <div class="col-sm-3">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="regularServiceIntervalMonths" id="regularServiceIntervalMonths1" value="12" v-model="entity.regularServiceIntervalMonths" :disabled="!worker.isAdmin">
              <label class="form-check-label" for="regularServiceIntervalMonths1">12</label>
            </div>
          </div>
          <DateOnly class="col-sm-5" v-model="entity.lastService" :disabled="!worker.isAdmin" />
        </div>


        <div class="row mb-3" v-show="(entity.hasCondenserCleaningLong || entity.hasCondenserCleaningShort)  && !isNew">
          <label class="col-form-label col-sm-4">Interval čiščenja kondenzatorja v [mesec]</label>
          <div class="col-sm-3">
            <div class="form-check form-check-inline" v-show="entity.hasCondenserCleaningShort">
              <input class="form-check-input" type="radio" name="condenserCleaningIntervalMonths" id="condenserCleaningIntervalMonths1" value="3" v-model="entity.condenserCleaningIntervalMonths" :disabled="!worker.isAdmin">
              <label class="form-check-label" for="condenserCleaningIntervalMonths1">3</label>
            </div>
            <div class="form-check form-check-inline" v-show="entity.hasCondenserCleaningLong">
              <input class="form-check-input" type="radio" name="condenserCleaningIntervalMonths" id="condenserCleaningIntervalMonths2" value="6" v-model="entity.condenserCleaningIntervalMonths" :disabled="!worker.isAdmin">
              <label class="form-check-label" for="condenserCleaningIntervalMonths2">6</label>
            </div>
            <div class="form-check form-check-inline" v-show="entity.hasCondenserCleaningLong">
              <input class="form-check-input" type="radio" name="condenserCleaningIntervalMonths" id="condenserCleaningIntervalMonths3" value="12" v-model="entity.condenserCleaningIntervalMonths" :disabled="!worker.isAdmin">
              <label class="form-check-label" for="condenserCleaningIntervalMonths3">12</label>
            </div>
          </div>
          <DateOnly class="col-sm-5" v-model="entity.lastCondenserCleaning" :disabled="!worker.isAdmin" />
        </div>


        <div class="row mb-3" v-show="entity.hasCO2Sensor && !isNew">
          <label class="col-form-label col-sm-4">CO2 senzor</label>
          <div class="col-sm-3">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="cO2SensorIntervalMonths" id="cO2SensorIntervalMonths1" value="0" v-model="entity.cO2SensorIntervalMonths" :disabled="!worker.isAdmin">
              <label class="form-check-label" for="cO2SensorIntervalMonths1">0</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="cO2SensorIntervalMonths" id="cO2SensorIntervalMonths2" value="12" v-model="entity.cO2SensorIntervalMonths" :disabled="!worker.isAdmin">
              <label class="form-check-label" for="cO2SensorIntervalMonths2">12</label>
            </div>
          </div>
          <DateOnly class="col-sm-5" v-model="entity.lastCO2Sensor" :disabled="!worker.isAdmin" />
        </div>

        <div class="row mb-3" v-show="entity.hasThermometerCalibration && !isNew">
          <label class="col-form-label col-sm-4">Kalibracija termometra</label>
          <div class="col-sm-3">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="thermometerCalibrationIntervalMonths" id="thermometerCalibrationIntervalMonths1" value="24" v-model="entity.thermometerCalibrationIntervalMonths" :disabled="!(isNew || worker.isAdmin)">
              <label class="form-check-label" for="thermometerCalibrationIntervalMonths1">24</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="thermometerCalibrationIntervalMonths" id="thermometerCalibrationIntervalMonths2" value="720" v-model="entity.thermometerCalibrationIntervalMonths" :disabled="!(isNew || worker.isAdmin)">
              <label class="form-check-label" for="thermometerCalibrationIntervalMonths2">Brez</label>
            </div>
          </div>
          <DateOnly class="col-sm-5" v-model="entity.lastThermometerCalibration" :disabled="!(isNew || worker.isAdmin)" />
        </div>


        <div class="row mb-3">
          <label class="col-form-label col-sm-4">Opis</label>
          <div class="col-sm-8">
            <textarea class="form-control" rows="5" v-model="entity.description" :disabled="!(isNew || worker.isAdmin)"></textarea>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card mb-3" v-if="!isNew && entity.facilityID !== $EMPTY_GUID">
          <div class="card-body">
            <h5 class="card-title d-none d-lg-block">Zadnji pregledi</h5>
            <p class="card-text" v-show="entity.lastLeakageTestObject">Preverjanje uhajanja: {{ entity.lastLeakageTestObject?.format($DATE_FORMAT_DISPLAY) }}</p>
            <p class="card-text" v-show="entity.lastServiceObject">Servis: {{ entity.lastServiceObject?.format($DATE_FORMAT_DISPLAY) }}</p>
            <p class="card-text" v-show="entity.lastCondenserCleaningObject">Čiščenje kondenzatorjev: {{ entity.lastCondenserCleaningObject?.format($DATE_FORMAT_DISPLAY) }}</p>
            <p class="card-text" v-show="entity.lastCO2SensorObject">CO2 senzor: {{ entity.lastCO2SensorObject?.format($DATE_FORMAT_DISPLAY) }}</p>
            <p class="card-text" v-show="entity.lastThermometerCalibrationObject">Kalibracija termometra: {{ entity.lastThermometerCalibrationObject?.format($DATE_FORMAT_DISPLAY) }}</p>
            <p class="card-text"><button class="btn btn-sm btn-primary" @click="showFacilityHistory = true">Pretekli obiski na objektu</button></p>
          </div>
        </div>

        <div class="card mb-3">
          <div class="card-body">
            <h5 class="card-title d-none d-lg-block">Kontrolni listi termometrov</h5>
            <p class="card-text">
              <ul>
                <li v-for="cr in entity.calibrationReports">
                  <a :href="$AZURE_URI + '/Generated/CalibrationReport/' + cr.serviceOrderID + '?deviceId=' + entity.id" target="_blank">{{ $dayjs(cr.dateCreated).format($DATE_FORMAT_DISPLAY) }}</a>
                </li>
              </ul>
            </p>
          </div>
        </div>

      </div>
    </div>

    <FacilityHistory :facilityId="entity.facilityID" @close="showFacilityHistory = false" :visible="showFacilityHistory"  />

    <hr v-show="entity?.hasAttributes" />

    <div class="row mb-3" v-for="a in entity?.attributes">
      <label class="col-form-label col-sm-4">{{ translations.get(a.name) || a.name }}</label>
      <div class="col-sm-8" v-if="a.typeText === 'String'">
        <input class="form-control" type="text" v-model="a.value" :disabled="!(worker.isAdmin)" />
      </div>
      <div class="col-sm-8" v-else-if="a.typeText === 'Enum' && a.options.length > 9">
        <select class="form-select" v-model="a.value" :disabled="!(worker.isAdmin)">
          <option value=""></option>
          <option v-for="value in a.options" :value="value">{{ value }}</option>
        </select>
      </div>
      <div class="col-sm-8" v-else-if="a.typeText === 'Enum' && a.options.length < 10">
        <div class="form-check form-check-inline" v-for="(value, index) in a.options">
          <input class="form-check-input" type="radio" :name="a.name" :id="a.name + index" :value="value" v-model="a.value" :disabled="!(worker.isAdmin)">
          <label class="form-check-label" :for="a.name + index">{{ value }}</label>
        </div>
      </div>
      <div class="col-sm-8" v-else-if="a.typeText === 'EnumMulti'">
        <div class="form-check form-check-inline" v-for="(value, index) in a.options">
          <input class="form-check-input"
            type="checkbox"
            :name="a.name"
            :id="a.name + index"
            :value="value"
            :checked="isValueIncluded(a, value)"
            :disabled="!(worker.isAdmin)"
            @change="e => a.value = getEnumMultiValue(a.value, e.target.checked, value)">
          <label class="form-check-label" :for="a.name + index">{{ value }}</label>
        </div>
      </div>
      <div class="col-sm-2" v-else-if="a.typeText === 'PositiveInt'">
        <input class="form-control" type="number" v-model="a.value" :disabled="!(worker.isAdmin)" pattern="[0-9]+(?<!0)" />
      </div>
      <div class="col-sm-2" v-else-if="a.typeText === 'PositiveDecimal'">
        <input class="form-control" type="number" v-model="a.value" :disabled="!(worker.isAdmin)" pattern="[0-9.]+" />
      </div>
      <div class="col-sm-8" v-else-if="a.typeText === 'Image'">
        <ImageInput v-model="a.value" :disabled="!(worker.isAdmin)" allowed-type="image"  />
      </div>
      <div class="col-sm-8" v-else-if="a.typeText === 'Boolean'">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" v-model="a.value">
        </div>
      </div>
      <div class="col-sm-8" v-else>
        {{ a }}
      </div>
    </div>

    <RequiredWarning v-show="!isValid" />

    <div class="row mb-5">
      <div class="col-2 text-start">
      </div>
      <div class="col-10 text-end">
        <button class="btn btn-outline-secondary me-3" type="button" @click="$router.push({ name: 'Devices' })">Prekliči</button>
        <button class="btn btn-success btn-lg me-3" type="button" @click="saveEntityAsync" :disabled="!isValid || !(worker.isAdmin || isNew)">Shrani</button>
      </div>
    </div>

    <ChangeHistory v-model="entity" v-show="!isNew" />

  </div>
  `,
  setup(props, { emit }) {
    const { deviceTypes, translations, loadEnumsAsync } = useEnums()
    const route = VueRouter.useRoute()
    const router = VueRouter.useRouter()
    const { instance } = useMsal()
    const { worker } = useIsAuthorized()

    const entity = ref()
    const showFacilityHistory = ref(false)

    const isNew = computed(() => route.params.deviceId === "new")

    const isValid = computed(
      () =>
        entity.value?.label?.length > 0 &&
        entity.value.facilityID !== EMPTY_GUID &&
        entity.value.typeText !== "Unknown" &&
        (!entity.value.isSlave || entity.value.sourceDeviceID !== EMPTY_GUID)
    )

    const loadEntityAsync = async () => {
      entity.value = await getDeviceAsync(instance, route.params.deviceId)
    }

    const saveEntityAsync = async () => {
      const result = await saveDeviceAsync(instance, entity.value, isNew.value)
      if (!result) return
      if (isNew.value) {
        router.push({ name: "DeviceEdit", params: { deviceId: entity.value.id, keepMessages: true } })
      } else {
        await loadEntityAsync()
      }
    }

    const getEnumMultiValue = (currentValue, checked, value) => {
      let values = currentValue ? currentValue.split(",") : []
      if (checked) {
        if (!values.includes(value)) {
          values.push(value)
        }
      } else {
        const index = values.indexOf(value)
        if (index !== -1) {
          values.splice(index, 1)
        }
      }
      return values.join(",")
    }

    const isValueIncluded = (a, value) => {
      return a.value.split(",").includes(value)
    }

    watch(
      () => route.params.deviceId,
      () => loadEntityAsync()
    )

    onMounted(async () => {
      await loadEnumsAsync()
      await loadEntityAsync()
      if (isNew.value) {
        entity.value.facilityID = route.params.facilityId
        entity.value.customerID = route.params.customerId
        console.log(route.params.facilityId)
      }
    })

    return {
      worker,
      isNew,
      isValid,
      entity,
      deviceTypes,
      translations,
      saveEntityAsync,
      showFacilityHistory,
      getEnumMultiValue,
      isValueIncluded,
    }
  },
}

export default DeviceEdit
