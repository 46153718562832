import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'

export function useSerialNumbers(material) {

  const numberOfSerialNumbers = computed(() => material.value?.serialNumbers?.split("\n").filter(l => l.length > 2).filter(l => /^\d+$/.test(l)).length || 0)

  const serialNumbersValid = computed(() => numberOfSerialNumbers.value === parseInt(material.value?.quantity))

  return { numberOfSerialNumbers, serialNumbersValid }
}
